import { useStaticQuery, graphql } from "gatsby"

export const useBlogsQuery = () => {
    const querydata = useStaticQuery(graphql`
        query getAllPostsInfo {
            allPosts: allMarkdownRemark (
                filter: {fileAbsolutePath: {regex: "/posts/"}}
                sort: { order: DESC, fields: [frontmatter___date] }
            ) {
                nodes {
                    id
                    fields {
                        slug
                        language
                    }
                    frontmatter {
                        title
                        date
                        author
                        category
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1000, quality: 90, traceSVG: {color: "#2B2B2F"}) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
            }
            allCategories: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/categories/"}}, sort: {order: DESC, fields: [frontmatter___date]}) {
                nodes {
                  fields {
                    language
                  }
                  frontmatter {
                    title
                  }
                }
            }
        }
    `)

  return querydata
}