import React from 'react'
import { useInView } from 'react-intersection-observer';

const SectionContainer = ( { id, cssPrefix, style, children } ) => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0.3,
        triggerOnce: true,
      });
  
    return ( 
        <div id={`${id}`} className={`section ${cssPrefix} ${cssPrefix}--${style} ${(inView) ? 'animated' : ''}`} ref={ref}>
            {children}
        </div>
    )
}

export default SectionContainer