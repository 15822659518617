import React from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { Image } from '../../components/_all' 
import './footer.scss'

const Footer = ( { properties } ) => {
  const { style, offices, links, copyright} = properties
  const {t} = useTranslation()
  const cssPrefix = `ss-footer--${style}`

  const renderOffices = offices.map( (item, index)  => {
    return (
      <div className={`${cssPrefix}__office`} key={index}>
        <div className={`${cssPrefix}__heading`}>
          <h3>{t(item.city)}</h3> 
          <h4>{t(item.country)}</h4>     
          <div className={`${cssPrefix}__image-container`}>
            <Image src={item.image.path} alt={item.image.alt} />
          </div>
        </div>
        <div className={`${cssPrefix}__details`}>
          {
            item.details.map( (detail, index)  => {
              return (<p key={index}>{t(detail)}</p>)
            })
          }
        </div>
      </div>
    )
  }) 

  const renderLinks = links.map( (item, index)  => {
    if(item.type === 'nav') {
      return (
        <li className={`${cssPrefix}__nav-item`} key={index}>
          <Link to={item.href} className={`ss-footer__link`}>{t(item.label)}</Link>
        </li>
      )
    }
    return (
      <li className={`${cssPrefix}__nav-item`} key={index}>
        <a href={item.href} target='_blank' rel='noreferrer' className={`ss-footer__link`}>{t(item.label)}</a>
      </li>
    )
  }) 
  
  return ( 
    <div className={`${cssPrefix} neutral`}>
      <div className={`${cssPrefix}__offices`} id='branches'>
        {renderOffices}
      </div>
      <hr/>
      <div className={`${cssPrefix}__container`}>
        <div className={`${cssPrefix}__links`}>
          {renderLinks}
        </div>
        <div className={`${cssPrefix}__copyright`}>
          <p>{t(copyright)}</p>
        </div>
      </div>
    </div>
  )
}

export default Footer