import React from "react"
import "./layout.scss"

import Header from './header/header'
import Footer from './footer/footer'


const Layout = ({ className, headerProps, footerProps, children }) => {
  return (
    <>
      <div className={className}>
        <Header properties={headerProps}></Header>
        {children}
        <Footer properties={footerProps}></Footer>
      </div>
    </>
  )
}

export default Layout