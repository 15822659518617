import React from 'react'
import { Helmet } from 'react-helmet'
 
const imgContentTypes = {
    jpg: 'jpeg',
    png: 'png',
    svg: 'svg'
}

// Safari 3.0+ "[object HTMLElementConstructor]" 

const Image = ({ src, alt, critical, ...rest }) => {

    let filePath = src.split('/')
    const fileName= filePath.pop()
    const [imgName, imgType] = fileName.split('.')
    const srcWebp = `${filePath.join('/')}/${imgName}.webp`
    const isSVG = imgContentTypes[imgType] === 'svg' ? true : false

    const renderCritical = () => {
        if ( critical ) {
            if ( isSVG) { // Safari does not support webp
                return (
                    <Helmet>
                        <link rel="preload" as="image" href={src}></link>
                    </Helmet>
                )
            }
            return (
                <Helmet>
                    <link rel="preload" as="image" href={srcWebp}></link>
                </Helmet>
            )
        }
    }
 
    if ( isSVG ) {
        return (
            <img {...rest} src={src} alt={alt}/>
        )
    }
    return (
        <>
            <picture>
                <source srcSet={srcWebp} type="image/webp" />
                <img {...rest} src={src} alt={alt}/>
            </picture>
            { renderCritical() }
        </>
    )
}
 
export default Image