
import React from 'react'
import { Helmet } from 'react-helmet'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { useStaticQuery, graphql } from 'gatsby'

const Head = () => {
    const {t} = useTranslation();
    const { defaultLanguage, language, languages, originalPath} = useI18next();
    const renderAltHref = languages.map((lng, index) => {
        const url =  defaultLanguage === lng ? originalPath : '/' + lng + originalPath
        const hrefTag = language !== lng ? <link key={index} rel="alternate" hrefLang={lng} href={url} /> : null
        return hrefTag
    })


    const data = useStaticQuery(graphql`
        query{
            site{
                siteMetadata {
                    title
                    description
                    siteUrl
                    image
                    keywords
                }
            }
        }
    `)

    return (
        <Helmet>
            {/* General tags */}
            <title>{t(data.site.siteMetadata.title)}</title>
            <meta name="description" content={t(data.site.siteMetadata.description)}/>
            <meta name="keywords" content={t(data.site.siteMetadata.keywords)}></meta>
            {/* Favicon */}
            <link rel="apple-touch-icon" sizes="57x57" href="/favicon/apple-icon-57x57.png"/>
            <link rel="apple-touch-icon" sizes="60x60" href="/favicon/apple-icon-60x60.png"/>
            <link rel="apple-touch-icon" sizes="72x72" href="/favicon/apple-icon-72x72.png"/>
            <link rel="apple-touch-icon" sizes="76x76" href="/favicon/apple-icon-76x76.png"/>
            <link rel="apple-touch-icon" sizes="114x114" href="/favicon/apple-icon-114x114.png"/>
            <link rel="apple-touch-icon" sizes="120x120" href="/favicon/apple-icon-120x120.png"/>
            <link rel="apple-touch-icon" sizes="144x144" href="/favicon/apple-icon-144x144.png"/>
            <link rel="apple-touch-icon" sizes="152x152" href="/favicon/apple-icon-152x152.png"/>
            <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-icon-180x180.png"/>
            <link rel="icon" type="image/png" sizes="192x192"  href="/favicon/android-icon-192x192.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="96x96" href="/favicon/favicon-96x96.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png"/>
            <link rel="manifest" href="/favicon/manifest.json"/>
            <meta name="msapplication-TileColor" content="#ffffff"/>
            <meta name="msapplication-TileImage" content="/favicon/ms-icon-144x144.png"/>
            <meta name="theme-color" content="#ffffff"></meta>
            {/* Localized Tags */}
            <link key="x-default" rel="alternate" hrefLang='x-default' href={originalPath} /> 
            {renderAltHref}
            {/* OpenGraph tags */}
            <meta name="og:url" content={t(data.site.siteMetadata.url)} />
            <meta name="og:title" content={t(data.site.siteMetadata.title)} />
            <meta name="og:description" content={t(data.site.siteMetadata.description)} />
            <meta name="og:image" content={data.site.siteMetadata.image} />
            <meta name="og:type" content="website" />
            {/* Twitter Card tags */}
            <meta name="twitter:title" content={t(data.site.siteMetadata.title)} />
            <meta name="twitter:description" content={t(data.site.siteMetadata.description)} />
            <meta name="twitter:image" content={data.site.siteMetadata.image} />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}


export default Head