import React from 'react'
import {useTranslation} from 'gatsby-plugin-react-i18next';

const SectionEyebrow = ( { number, title, styles } ) => {
    const {t} = useTranslation();
  
    return ( 
        <span className={`ss-section__eyebrow ${styles}`}>{number ? number : ''}<hr className={styles}/>{t(title)}</span>
    )
}

export default SectionEyebrow