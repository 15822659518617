import React from 'react'
import Navbar  from 'react-bootstrap/Navbar';
import Nav  from 'react-bootstrap/Nav';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { Image, LangSwitcher } from '../../components/_all' 
import './header.scss'

const Header = ( { properties } ) => {
  const {t} = useTranslation()
  const cssPrefix = 'ss-header'

  const renderNavItems = properties.items.map( (navItem, index)  => {
    return (
      <li className={`${cssPrefix}__nav-item`} key={index}>
        <Link to={navItem.href} className={`ss-nav-link`}>{t(navItem.title)}</Link>
      </li>
    )
  }) 
  
  return ( 
    <div className={`${cssPrefix}`}>
      <Navbar className={`${cssPrefix}__navbar-expand`} bg="white" expand="md">
        <Navbar.Brand className={`${cssPrefix}__brand col-9 col-md-3`} >
          <Link to="/">
            <Image className={`${cssPrefix}__image`} src={properties.logo.path} alt={properties.logo.alt}/>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className={`${cssPrefix}__navbar-collapse`}>
          <Nav className={`${cssPrefix}__nav`}>
            <ul className='ss-header__nav-list'>
              {renderNavItems}
              <LangSwitcher className={`${cssPrefix}__nav-item ${cssPrefix}__lang-switcher  ss-toggle neutral`} linkStyles='ss-toogle-tab neutral'/>
            </ul>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default Header