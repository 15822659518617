import React from 'react'
import {Link, useI18next} from 'gatsby-plugin-react-i18next';

const LangSwitcher = ( { className, linkStyles } ) => {
    const {language, languages, originalPath} = useI18next();
  
    const renderLangList = languages.map((lng) => {
        const activeClass = language === lng ? 'ss-toogle-tab--active' : ''
        
        return (
            <li className='lang-li' key={lng}>
                <Link to={originalPath} language={lng} className={`${linkStyles} ${activeClass}`}>
                    {lng}
                </Link>
            </li>
        )
    })

    return ( 
        <div className={className}>
            <ul className="lang-list">
                {renderLangList}
            </ul>
        </div>
    )
}

export default LangSwitcher