import React, { useState } from 'react'
import { Link } from 'gatsby-plugin-react-i18next';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBlogsQuery } from '../../hooks/useBlogsQuery'
import Img from "gatsby-image"
import './blogPostsGrid.scss'

const BlogPostsGrid = ({options}) => {
    const {numRows = 1, showCategories = false, showMore = false, language = 'en'} = options
    let { posts } = options
    const {t} = useTranslation();

    const querydata = useBlogsQuery()
    const { allPosts, allCategories } = querydata;

    if ( typeof posts === 'undefined' ) {
      // Filter by language
       posts = allPosts.nodes.filter(post => post.fields.language === language );
    }
    
    let categories = allCategories.nodes.filter(category => category.fields.language === language );
    const ALL_CATEGORY = {
      fields: {
        language: language
      },
      frontmatter: {
        title: 'All'
      }
    }
    categories.unshift(ALL_CATEGORY)

    const [selectedCategory, setSelectedCategory] = useState('All')
    const [filteredPosts, setFilteredPosts] = useState(posts)

    const updateCategory = (category) => {
      setSelectedCategory(category)
      console.log(`update filter: ${category}`)
      if ( category !== 'All') {
        setFilteredPosts(posts.filter( post => post.frontmatter.category === category))
      } else {
        setFilteredPosts(posts);
      }
      console.log(filteredPosts); 
    }

    const renderCategories = categories.map( (category, index) => {
      const activeCategory = (selectedCategory === category.frontmatter.title) ? true : false
      const className = `blog-category-btn ${ (activeCategory) ? 'active' : '' }`
      
      return (
        <button className={className} key={index} onClick={() => updateCategory(category.frontmatter.title)}>
          <p>{category.frontmatter.title}</p>
        </button>
      )      
    })

    const renderCategorySection = () => {
      if ( showCategories ) {
        return (
          <div className={`blog-categories--medium`}>
            {renderCategories}
          </div>
        )
      }
      return (
        <></>
      )
    }

    const renderPosts = filteredPosts.map( (post, index) => {
        return (
          <div className='blog-post-wrapper' key={index}>
            <Link to={post.fields.slug} className='link'>
              <div className={`header`}>
                <div className={`cover-image`}>
                  <Img fluid={post.frontmatter.image.childImageSharp.fluid} />
                </div>
                <div className={`category`}><p>{post.frontmatter.category}</p></div>
              </div> 
              <div className={`title`}>
                <h4>{post.frontmatter.title}</h4>
              </div>
              <div className={`author`}>
                <p>{t('By')}: {post.frontmatter.author}</p>
              </div>
            </Link>
          </div>
        )
    })

    return (
      <div>
        {renderCategorySection()}
        <div className={`blog-posts-grid__container`}>
          {renderPosts}
        </div>
      </div>
        
    )
}
 
export default BlogPostsGrid